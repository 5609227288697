import { GameState } from '../../App'
import {
  TOTAL_ROUNDS_TEXT,
  TOTAL_STALEMATES_TEXT,
  AVERAGE_GUESSES,
} from '../../constants/strings'

type Props = {
  gameState: GameState
}

const StatItem = ({
  label,
  value,
}: {
  label: string
  value: string | number
}) => {
  return (
    <div className="items-center justify-center m-1 w-1/4 dark:text-white">
      <div className="text-3xl font-bold">{value}</div>
      <div className="text-xs">{label}</div>
    </div>
  )
}

export const RoundStatBar = ({ gameState }: Props) => {
  
  var rounds = gameState.roundRecords.length;
  var stalemates = 0;
  var averageGuesses = 0;
  
  if (rounds > 0) {
    for (var i = 0; i < rounds; i++) {
      const record = gameState.roundRecords[i];
      if (record.points.every(p => p === 0)) {
        stalemates++;
      }
      else {
        averageGuesses += record.guesses;
      }
    }
    
    var scoredRounds = rounds-stalemates;
    if (scoredRounds > 0) {
      averageGuesses /= (rounds-stalemates);  
    }
    else {
      averageGuesses = 0;
    }
  }
  
  return (
    <div className="flex justify-center my-2">
      <StatItem label={TOTAL_ROUNDS_TEXT} value={gameState.roundRecords.length} />
      <StatItem label={TOTAL_STALEMATES_TEXT} value={stalemates} />
      <StatItem label={AVERAGE_GUESSES} value={averageGuesses.toPrecision(1)} />
    </div>
  )
}
