import classnames from 'classnames'
import { useState } from 'react'
import { useAlert } from '../../context/AlertContext'

type Props = {
  handleClick: Function
  enabled: boolean
  description?: string
  disabledMessage?: string
}

export const SettingsButton = ({
  handleClick,
  enabled,
  description,
  disabledMessage,
}: Props) => {
  
  const { showError: showErrorAlert } = useAlert()
  
  const [currentButtonClass, setCurrentButtonClass] = useState('')
  
  const classes = classnames(
    `mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm ${currentButtonClass}`,
    {
      'bg-indigo-600 hover:bg-indigo-700': enabled,
      'bg-indigo-400 hover:bg-indigo-500': !enabled
    }
  )

  return (
    <button
      type="button"
      className={classes}
      onClick={() => {
        if (enabled) {
          if (handleClick) {
            handleClick();
          }
        }else {
          if (disabledMessage) 
          {
            setCurrentButtonClass('jiggle')
            return showErrorAlert(disabledMessage, {
              onClose: () => setCurrentButtonClass(''),
            })
          } 
        }
      }}
    >
      {description}
    </button>
  )
}
