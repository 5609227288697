import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'
import { SettingsButton } from './SettingsButton'
import { GAME_TITLE } from '../../constants/strings'

type Props = {
  isOpen: boolean
  handleClose: () => void
  isPreGame: boolean,
  isHighContrast: boolean
  handleTwindleInfo: () => void
  handleNewGame: () => void,
}

export const RulesInfoModal = ({ isOpen, handleClose, isPreGame, isHighContrast, handleTwindleInfo, handleNewGame}: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} showCloseButton={!isPreGame} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        In {GAME_TITLE} and similar games, you must guess your goal word before 
        you run out of tries. After each guess, the color of the tiles will
        change to show how close your guess was to the word.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell
          isHighContrast={isHighContrast}
          isRevealing={true}
          isCompleted={true}
          value="W"
          status="correct"
        />
        <Cell isHighContrast={isHighContrast} value="E" />
        <Cell isHighContrast={isHighContrast} value="A" />
        <Cell isHighContrast={isHighContrast} value="R" />
        <Cell isHighContrast={isHighContrast} value="Y" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter W is in the word and in the correct spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell isHighContrast={isHighContrast} value="P" />
        <Cell isHighContrast={isHighContrast} value="I" />
        <Cell
          isHighContrast={isHighContrast}
          isRevealing={true}
          isCompleted={true}
          value="L"
          status="present"
        />
        <Cell isHighContrast={isHighContrast} value="O" />
        <Cell isHighContrast={isHighContrast} value="T" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter L is in the word but in the wrong spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell isHighContrast={isHighContrast} value="V" />
        <Cell isHighContrast={isHighContrast} value="A" />
        <Cell isHighContrast={isHighContrast} value="G" />
        <Cell isHighContrast={isHighContrast} isRevealing={true} isCompleted={true} value="U" status="absent" />
        <Cell isHighContrast={isHighContrast} value="E" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter U is not in the word in any spot.
      </p>
      
      <SettingsButton
        handleClick={handleTwindleInfo}
        enabled={true}
        description='Learn more about Twindle'
      />
      
      {isPreGame && <SettingsButton
        handleClick={handleNewGame}
        enabled={true}
        description='Start game'
      />}
    </BaseModal>
  )
}
