import classnames from 'classnames'

type Props = {
  value?: string
  changed?: boolean
}

export const Score = ({
  value,
  changed,
}: Props) => {

  const classes = classnames(
    'text-center mb-2 text-4xl md:text-6xl font-bold dark:text-slate-200 text-slate-300 select-none ',
    {
      'score-reveal': changed,
    }
  )

  return (
    <div className={classes}>{value}</div>
  )
}
