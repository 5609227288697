import { MAX_GUESSES } from '../constants/settings'
import {
  GameStats,
  loadStatsFromLocalStorage,
  saveStatsToLocalStorage,
} from './localStorage'

export const getGuessCountStats = (
  gameStats: GameStats,
  guesses: number,
) => {
  const index = guesses - 1;
  if (index >= 0 && index < gameStats.guessDistribution.length) 
  {
    const count = gameStats.guessDistribution[index];
    if (count !== undefined && count !== null && !Number.isNaN(count)) {
      return count;
    }
  }
  
  return 0
}
export const setGuessCountStats = (
  gameStats: GameStats,
  guesses: number,
  count: number
) => {
  const stats = { ...gameStats }
  
  if (guesses >= 1) 
  {
    stats.guessDistribution[guesses-1] = count;
    saveStatsToLocalStorage(stats) 
  }
  
  return stats
}

export const addStatsForGame = (
  gameStats: GameStats,
  winner: number
) => {
  // Count is number of incorrect guesses before end.
  const stats = { ...gameStats }

  stats.totalGames += 1
  stats.wins[winner] += 1
  
  if (stats.currentStreakPlayer !== winner) {
    stats.currentStreakPlayer = winner;
    stats.currentStreak = 1;
  }
  else {
    stats.currentStreak++;
  } 
  
  if (stats.bestStreak < stats.currentStreak) {
    stats.bestStreak = stats.currentStreak
    stats.bestStreakPlayer = stats.currentStreakPlayer
  }

  saveStatsToLocalStorage(stats)
  return stats
}

const defaultStats: GameStats = {
  totalGames: 0,
  wins: Array.from(new Array(2), () => 0),
  guessDistribution: Array.from(new Array(MAX_GUESSES+1), () => 0),
  currentStreak: 0,
  currentStreakPlayer: 0,
  bestStreak: 0,
  bestStreakPlayer: 0
}

export const resetStats = () => {
  saveStatsToLocalStorage(defaultStats);
  return defaultStats;
}

export const loadStats = () => {
  return loadStatsFromLocalStorage() || defaultStats
}
