import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'
import { GAME_TITLE } from '../../constants/strings'
import { TurnBarCircle } from '../turnbar/TurnBar' 
import { SettingsButton } from './SettingsButton'

type Props = {
  isOpen: boolean,
  handleClose: () => void,
  isPreGame: boolean,
  isHighContrast: boolean
  handleRulesInfo: () => void,
  handleNewGame: () => void,
}

export const TwindleInfoModal = ({ isOpen, handleClose, isPreGame, isHighContrast, handleRulesInfo, handleNewGame }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} showCloseButton={!isPreGame} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        {GAME_TITLE} is a word-guessing game for two players.
        Score points by guessing your word before your opponent guesses theirs.
      </p>
      <div className="flex justify-center mb-1 mt-4">
        <Cell value="M" isHighContrast={isHighContrast} status={'present'} isRevealing={false} isCompleted={false} position={0}/>
        <Cell value="A" isHighContrast={isHighContrast} status={'correct'} isRevealing={true} isCompleted={true} position={1}/>
        <Cell value="T" isHighContrast={isHighContrast} status={'absent'} isRevealing={false} isCompleted={false}/>
        <Cell value="C" isHighContrast={isHighContrast} status={'absent'} isRevealing={false} isCompleted={false}/>
        <Cell value="H" isHighContrast={isHighContrast} status={'absent'} isRevealing={false} isCompleted={false}/>
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        On your turn, guess a five-letter word. Your guess appears on <i>both</i> sides of the board, giving information to <i>both</i> players!
      </p>
      <div className="flex justify-center mb-1 mt-4">
        <Cell value="G" isHighContrast={isHighContrast} status={'correct'} isRevealing={true} isCompleted={true} position={0}/>
        <Cell value="A" isHighContrast={isHighContrast} status={'correct'} isRevealing={true} isCompleted={true} position={1}/>
        <Cell value="M" isHighContrast={isHighContrast} status={'correct'} isRevealing={true} isCompleted={true} position={2}/>
        <Cell value="E" isHighContrast={isHighContrast} status={'correct'} isRevealing={true} isCompleted={true} position={3}/>
        <Cell value="S" isHighContrast={isHighContrast} status={'correct'} isRevealing={true} isCompleted={true} position={4}/>
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The first correct player earns 2 points. Their opponent then has one last chance to guess their word for 1 point.
      </p>

      <div className="flex justify-center mb-2 mt-4">
        <TurnBarCircle filled={true} jiggle={false}/>
        <TurnBarCircle filled={true} jiggle={true}/>
        <TurnBarCircle filled={false} jiggle={false}/>
        <TurnBarCircle filled={false} jiggle={false}/>
        <TurnBarCircle filled={false} jiggle={false}/>
        <TurnBarCircle filled={false} jiggle={false}/>
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        You have a limited time for each guess.
        If you run out, it's your opponent's turn. 
        After six skipped turns, the round ends and a new round begins.
      </p>

      <SettingsButton
        handleClick={handleRulesInfo}
        enabled={true}
        description='Learn more about guessing'
      />      
      
      {isPreGame && <SettingsButton
        handleClick={handleNewGame}
        enabled={true}
        description='Start game'
      />}
    </BaseModal>
  )
}
