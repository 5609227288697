
type Props = {
  settingName: string
  value: number
  min: number
  max: number
  step?: number
  handleChange: Function
  description?: string
  customValueLabel?: string
}

export const SettingsRange = ({
  settingName,
  value,
  min,
  max,
  step,
  handleChange,
  description,
  customValueLabel,
}: Props) => {
  return (
    <>
      <div className="flex justify-between items-center gap-4 py-2 sm:py-3 text-gray-500 dark:text-gray-300 ">
        <div className="mt-1 sm:mt-2 text-left">
          <p className="leading-none">{`${settingName}`}</p>
          {description && (
            <p className="text-xs mt-1">
              {description}
            </p>
          )}
        </div>
        <input 
          tabIndex={-1}
          type="range" 
          min={min} 
          max={max} 
          value={value} 
          step={step !== undefined  ? step : "any"} 
          className="slider-sm sm:slider-rg" 
          onChange={e => handleChange(parseInt(e.target.value))}/>
          <p className="text-lg font-font-bold w-2 mr-3 sm:mr-4">{`${customValueLabel !== undefined ? customValueLabel : value}`}</p>
      </div>
    </>
  )
}
