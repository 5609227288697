import { useRef, useEffect } from 'react';

type Props = {
  fillPercentage: number
}

export const TimerRing = ({
  fillPercentage,
}: Props) => {
  let ref = useRef(null);
  
  useEffect(() => {
    if (ref.current === null) {
      return;
    }
    
    let canvas = ref.current as HTMLCanvasElement;
    let context = canvas.getContext('2d') as CanvasRenderingContext2D;
      
    let requestId : number;
    const render = () => {
      // Set actual size in memory (scaled to account for extra pixel density).
      var scale = window.devicePixelRatio; // Change to 1 on retina screens to see blurry canvas.
      canvas.width = Math.floor(canvas.offsetWidth * scale);
      canvas.height = Math.floor(canvas.offsetHeight * scale);
      
      // Normalize coordinate system to use CSS pixels.
      context.scale(scale, scale);
      
      var style = window.getComputedStyle(canvas);
      var color = style.stroke;
      var lineWidth = Number.parseFloat(style.strokeWidth);
      
      var halfWidth = Math.min(canvas.offsetWidth, canvas.offsetHeight)* .5;
      var radius = halfWidth - lineWidth;
      var startAngle = Math.PI / 2;
      
      context.beginPath();
      context.lineWidth = lineWidth;
      context.strokeStyle = color;
      context.lineCap = "butt";
      context.arc(halfWidth, halfWidth, radius, startAngle, startAngle + (2 * Math.PI * fillPercentage));
      context.stroke();
       
      requestId = requestAnimationFrame(render);
    }
    
    render();
    return () => {
      cancelAnimationFrame(requestId);
    };
  });
  
  return (
    <canvas
        ref={ref}
        style={{ width: '100%', height: '100%' }}
        className="dark:stroke-slate-200 stroke-slate-300 stroke-[4px] md:stroke-[6.5px]"
    />
  );
}