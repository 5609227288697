import classnames from 'classnames' 
import { RoundStatBar } from '../stats/RoundStatBar'
import { shareStatus } from '../../lib/share'
import { BaseModal } from './BaseModal'
import { GameState, RoundRecord } from '../../App'
import { SettingsButton } from './SettingsButton'
import {
  SCORE_TITLE,
  ROUND_RECORDS_TEXT,
  SHARE_TEXT
} from '../../constants/strings'


const RecordEntry = (record: RoundRecord, index: number, isHighContrast: boolean) => {
  const points = record.points[index];
  const scored = points > 0;
  const textClasses = classnames(
    'mx-0.5 text-lg font-bold',
    {
      'text-black dark:text-white': !scored,
      'text-orange-500': scored && isHighContrast,
      'text-green-500': scored && !isHighContrast,
    });
    
  let text = record.solutions[index];
  if (scored) {
      text += ` +${points}`;
  }
  
  return <p className={textClasses}>{text}</p>
};

const RecordColumn = 
  ({score, records, index, isHighContrast}: 
  {score: number, records: RoundRecord[], index: number, isHighContrast: boolean}) => {
  
  // Don't show stalemates in the columns
  let scoredRecords = records.filter(r => !r.points.every(p => p === 0));
  let entries = scoredRecords.map(r => {
    return RecordEntry(r, index, isHighContrast)
  });
    
  return (
    <div key={index} className="grow flex flex-col items-center justify-center m-1 dark:text-white">
      <p className="text-2xl font-bold text-black dark:text-white mb-1">{score}</p>
      {entries}
    </div>
  )
}

type Props = {
  isOpen: boolean
  handleClose: () => void
  gameState: GameState,
  handleNewGame: () => void,
  handleShareToClipboard: () => void,
  isHighContrast: boolean,
}

export const ScoreModal = ({
  isOpen,
  handleClose,
  gameState,
  handleNewGame,
  handleShareToClipboard,
  isHighContrast
}: Props) => {
  
  return (
    <BaseModal
      title={SCORE_TITLE}
      isOpen={isOpen}
      showCloseButton={false} 
      handleClose={handleClose}
    >
      <RoundStatBar gameState={gameState} />
      <h4 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
        {ROUND_RECORDS_TEXT}
      </h4>
      <div className="flex flex-row divide-x-2 divide-solid divide-gray-300 dark:divide-gray-500 my-2">
        <RecordColumn key={0} score={gameState.scores[0]} records={gameState.roundRecords} index={0} isHighContrast={isHighContrast} />
        <RecordColumn key={1} score={gameState.scores[1]} records={gameState.roundRecords} index={1} isHighContrast={isHighContrast} />
      </div>
      
      <SettingsButton
        handleClick={() => { shareStatus(gameState, handleShareToClipboard)}}
        enabled={true}
        description={SHARE_TEXT}
      />
      
      <SettingsButton
        handleClick={handleNewGame}
        enabled={true}
        description='New game'
      />      
    </BaseModal>
  )
}
