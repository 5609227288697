import { GridLetter } from './GridLetter'
import { getCharStatuses } from '../../lib/statuses'

type Props = {
  solution: string,
  guesses: string[],
  isHighContrast: boolean,
  isRevealing?: boolean
}

export const LetterGrid = ({
  solution,
  guesses,
  isHighContrast,
  isRevealing
}: Props) => {
  const charStatuses = getCharStatuses(solution, guesses)

  return (
    // Use a second container div to avoid having this one automatically get stretched to fill by the flex above?
    <div>
    <div className="grow-0 justify-around content-around p-1 grid grid-cols-2 bg-slate-400 dark:bg-slate-900">
      {['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
        'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
        'U', 'V', 'W', 'X', 'Y', 'Z'].map((key) => (
        <GridLetter
          value={key}
          status={charStatuses[key]}
          isHighContrast={isHighContrast}
          isRevealing={isRevealing}
          key={key}
        />
      ))}
    </div>
    </div>
  )
}
