import { solutionLength } from '../../lib/words'
import { Cell } from './Cell'

type Props = {
  hidden: boolean
}


export const EmptyRow = ({hidden} : Props) => {
  const emptyCells = Array.from(Array(solutionLength))

  return (
    <div className="flex justify-center mb-1">
      {emptyCells.map((_, i) => (
        <Cell key={i} hidden={hidden}/>
      ))}
    </div>
  )
}
