import {
  SwitchHorizontalIcon,
} from '@heroicons/react/outline'
import { SKIPS_BEFORE_STALEMATE } from '../../constants/settings'
import { Tooltip } from '../tooltip/Tooltip'
import classnames from 'classnames'

type Props = {
  turnsSkipped: number,
  onClickSkipTurn: () => void
}

export const TurnBarCircle = ({
  filled,
  jiggle
} : {
  filled: boolean,
  jiggle: boolean
}) => {
  const classes = classnames(
    'w-2 h-2 sm:h-4 sm:w-4 md:h-6 md:w-6 mr-2 rounded-full align-middle',
    {
      'bg-slate-300 dark:bg-slate-500': filled,
      'ring-2 ring-inset ring-slate-300 dark:ring-slate-500 ': !filled,
      'stalemate-reveal': jiggle
    }
  )
  return <div className={classes}/>;
}

export const TurnBar = ({
  turnsSkipped,
  onClickSkipTurn
}: Props) => {
  
  let skipIcons = [];
  for (let i = 0; i < SKIPS_BEFORE_STALEMATE; i++) {
    
    const filled = i < turnsSkipped;
    const jiggle = i === turnsSkipped-1;
    
    skipIcons.push(<TurnBarCircle key={i} filled={filled} jiggle={jiggle}/>);
  }
  
  return (
    <div className="h-4 sm:h-6 px-2 sm:px-4 flex row-auto content-start items-center">
        <Tooltip message='Skip turn'>
        <SwitchHorizontalIcon
          className="h-4 w-4 md:h-6 md:w-6 mr-2 cursor-pointer stroke-black dark:stroke-slate-200"
          onClick={() => onClickSkipTurn()}
        />
        </Tooltip>
        {skipIcons}
    </div>
  )
}
