import { GameStats } from '../../lib/localStorage'
import {
  TOTAL_GAMES_TEXT,
  P1_WINS_TEXT,
  P2_WINS_TEXT,
  CURRENT_STREAK_MESSAGE,
  BEST_STREAK_MESSAGE,
} from '../../constants/strings'

type Props = {
  gameStats: GameStats
}

const StatItem = ({
  label,
  value,
}: {
  label: string
  value: string | number
}) => {
  return (
    <div className="items-center justify-center m-1 w-1/4 dark:text-white">
      <div className="text-3xl font-bold">{value}</div>
      <div className="text-xs">{label}</div>
    </div>
  )
}

export const StatBar = ({ gameStats }: Props) => {
  return (
    <div className="flex justify-center my-2">
      <StatItem label={TOTAL_GAMES_TEXT} value={gameStats.totalGames} />
      <StatItem label={P1_WINS_TEXT} value={gameStats.wins[0]} />
      <StatItem label={P2_WINS_TEXT} value={gameStats.wins[1]} />
      {gameStats.currentStreak > 0 && <StatItem label={CURRENT_STREAK_MESSAGE(gameStats.currentStreakPlayer)} value={gameStats.currentStreak} />}
      {gameStats.bestStreak > 0 && <StatItem label={BEST_STREAK_MESSAGE(gameStats.bestStreakPlayer)} value={gameStats.bestStreak} />}
    </div>
  )
}
