export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!
export const GAME_URL = process.env.REACT_APP_URL

export const WIN_MESSAGES = ['Great Job!', 'Awesome!', 'Well done!']
export const GAME_COPIED_MESSAGE = 'Copied to clipboard'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Not enough letters'
export const WORD_NOT_FOUND_MESSAGE = 'Word not found'

export const TUTORIAL_MESSAGES_DESCRIPTION =
  'For players still learning the rules'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'For improved color vision'
export const SHOW_TIMER_NUMBERS_DESCRIPTION = 'Disable to reduce visual noise'
export const POINTS_TO_WIN_DESCRIPTION = 'Score this high to end the game'
export const SECONDS_PER_TURN_DESCRIPTION = 'How long you have to guess each turn'


export const CORRECT_WORDS_MESSAGE = (solutionLeft: string, solutionRight: string) =>
  `The words were ${solutionLeft} and ${solutionRight}`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Must use ${guess} in position ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Guess must contain ${letter}`
export const ENTER_TEXT = 'Enter'
export const DELETE_TEXT = 'Delete'
export const STATISTICS_TITLE = 'Statistics'
export const GUESS_DISTRIBUTION_TEXT = 'Guess Distribution'
export const NEW_WORD_TEXT = 'New word in'
export const SHARE_TEXT = 'Share'
export const RESET_STATS_TEXT = 'Reset stats'

export const TOTAL_GAMES_TEXT = 'Total games'
export const P1_WINS_TEXT = 'P1 wins'
export const P2_WINS_TEXT = 'P2 wins'
export const CURRENT_STREAK_MESSAGE = (player: number) => `Current streak (P${player+1})`;
export const BEST_STREAK_MESSAGE = (player: number) => `Best streak (P${player+1})`;
export const CLEAR_STATS_TEXT = 'Reset stats'

export const SCORE_TITLE = 'Game Over'
export const ROUND_RECORDS_TEXT = 'Scores'
export const TOTAL_ROUNDS_TEXT = 'Total rounds'
export const TOTAL_STALEMATES_TEXT = 'Stalemates'
export const AVERAGE_GUESSES = 'Avg guesses'

export const DISCOURAGE_INAPP_BROWSER_TEXT =
  "You are using an embedded browser and may experience problems sharing or saving your results. We encourage you rather to use your device's default browser."

export const SHARE_MESSAGE = (winningWord: string) => `I just won ${GAME_TITLE} by guessing ${winningWord}! Play with a friend at ${GAME_URL}`;

export const ROUND_START_MESSAGE = (roundNumber: number) => `Round ${roundNumber+1}`;
export const TUTORIAL_ROUND_START_MESSAGE = (roundNumber: number, playerNumber: number) => `Round ${roundNumber+1}\nPlayer ${playerNumber+1} plays first`;
export const TUTORIAL_POINTS_EARNED_MESSAGE = (playerNumber: number, otherPlayerNumber: number, points: number, isLastChance: boolean) => {
  let message = `Correct! Player ${playerNumber+1} gains ${points} point${points > 1 ? "s" : ""}!`;
  if (!isLastChance) message += `\nPlayer ${otherPlayerNumber + 1} has one more chance to guess`;
  return message;
};
export const TUTORIAL_TURN_SKIPPED_MESSAGE = (nextPlayerNumber: number) => `Turn skipped!\nPlayer ${nextPlayerNumber+1} plays next`;
export const TUTORIAL_OUT_OF_TIME_MESSAGE = (nextPlayerNumber: number) => `Time up!\nPlayer ${nextPlayerNumber+1} plays next`;
export const TUTORIAL_GAME_START_MESSAGE = (pointsToWin: number) => `Game on!\nScore ${pointsToWin} point${pointsToWin > 1 ? "s" : ""} to win`;
export const TUTORIAL_LAST_CHANCE_FAILED_TEXT = "Bad luck!\nProceeding to next round";
export const TUTORIAL_LAST_CHANCE_OUT_OF_TIME_TEXT = "Time up!\nProceeding to next round";
export const TUTORIAL_OUT_OF_GUESSES_TEXT = "Out of guesses!\nProceeding to next round";
export const TUTORIAL_GAME_OVER_MESSAGE = (winnerPlayerNumber: number) => `Game over!\nPlayer ${winnerPlayerNumber+1} wins`;
export const TUTORIAL_STALEMATE_TEXT = "Stalemate!\nProceeding to next round";