import classnames from 'classnames'
import { LETTER_REVEAL_DELAY_MS } from '../../constants/settings'
import { CharStatus } from '../../lib/statuses'

type Props = {
  value: string
  status: CharStatus
  isHighContrast: boolean
  isRevealing?: boolean
}

export const GridLetter = ({
  value,
  status,
  isHighContrast,
  isRevealing,
}: Props) => {
  const classes = classnames(
    'flex items-center duration-500 justify-center w-6 h-6 m-0.5 text-xs md:w-8 md:h-8 md:text-base font-bold select-none dark:text-white',
    {
      'transition ease-in-out duration-700': isRevealing,
      'bg-slate-200 dark:bg-slate-600 hover:bg-slate-300 active:bg-slate-400':
        !status,
      'bg-slate-400 dark:bg-slate-800 text-white': status === 'absent',
      'bg-orange-500 hover:bg-orange-600 active:bg-orange-700 text-white':
        status === 'correct' && isHighContrast,
      'bg-cyan-500 hover:bg-cyan-600 active:bg-cyan-700 text-white':
        status === 'present' && isHighContrast,
      'bg-green-500 hover:bg-green-600 active:bg-green-700 text-white':
        status === 'correct' && !isHighContrast,
      'bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700 text-white':
        status === 'present' && !isHighContrast,
    }
  )

  const styles = {
    transitionDelay: isRevealing ? `${LETTER_REVEAL_DELAY_MS}ms` : 'unset',
  }

  return (
    <div
      style={styles}
      aria-label={`${value} ${status}`}
      className={classes}
    >
      {value}
    </div>
  )
}
