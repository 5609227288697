import classnames from 'classnames'

type direction = "left" |  "right"

type Props = {
  direction: direction,
  hidden: boolean
}

export const Arrow = ({
    direction,
    hidden
}: Props) => {  

  const containerClasses = classnames(
    'mt-20 md:mt-52 my-auto',
    {
      'invisible': hidden,
    }
  )
  
  const arrowClasses = classnames(
    'border-solid border-slate-200 border-y-transparent border-y-[40px] md:border-y-[75px]',
    {
      'border-r-[25px] md:border-r-[50px] border-l-0': direction === "left",
      'border-l-[25px] md:border-l-[50px] border-r-0': direction !== "left",
      "arrow-reveal": !hidden,
    }
  )
  
  return (
    <div className={containerClasses}>
      <div className={arrowClasses}></div>
    </div>
  )
}
