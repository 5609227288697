import { MAX_GUESSES } from '../../constants/settings'
import { CompletedRow } from './CompletedRow'
import { CurrentRow } from './CurrentRow'
import { RevealedRow } from './RevealedRow'
import { EmptyRow } from './EmptyRow'
import { Score } from './Score'
import { GridState } from '../../App'

type Props = {
  solution: string
  guesses: string[]
  currentGuess: string
  finalGuessIndex: number
  state: GridState
  score: string
  scoreChanged?: boolean
  isRevealing?: boolean
  currentRowClassName: string,
  isHighContrast: boolean
}

export const Grid = ({
  solution,
  guesses,
  currentGuess,
  state,
  finalGuessIndex,
  score,
  scoreChanged,
  isRevealing,
  currentRowClassName,
  isHighContrast
}: Props) => {
  
  const guessesToShow = finalGuessIndex >= 0 ? guesses.slice(0, finalGuessIndex+1) : guesses.slice(0, MAX_GUESSES);
  
  const showCurrentInput = state === 'open';
  const showFinalReveal = state === 'revealed';
  const showEmpties = true;
  const emptyCount = Math.max(0, MAX_GUESSES - guessesToShow.length - (showCurrentInput ? 1 : 0) - (showFinalReveal ? 1 : 0));
  const empties = Array.from(Array(emptyCount));

  return (
    <div className="pb-6 md:pt-2 flex flex-col">
      <Score value={score} changed={scoreChanged}/>
      {guessesToShow.map((guess, i) => (
        <CompletedRow
          key={i}
          solution={solution}
          guess={guess}
          isRevealing={isRevealing && guesses.length - 1 === i}
          isHighContrast={isHighContrast}
        />
      ))}
      {showCurrentInput && (
        <CurrentRow guess={currentGuess} className={currentRowClassName} />
      )}
      {
        showFinalReveal && (
        <RevealedRow solution={solution}/>   
      )}
      {empties.map((_, i) => (
        <EmptyRow key={i} hidden={!showEmpties}/>
      ))}
    </div>
  )
}
