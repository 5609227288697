import { useState, useEffect } from 'react'
import { Grid } from './components/grid/Grid'
import { LetterGrid } from './components/lettergrid/LetterGrid'
import { Keyboard } from './components/keyboard/Keyboard'
import { Arrow } from './components/arrows/Arrow'
import { Timer } from './components/timer/Timer'
import { TurnBar } from './components/turnbar/TurnBar'
import { TwindleInfoModal } from './components/modals/TwindleInfoModal'
import { RulesInfoModal } from './components/modals/RulesInfoModal'
import { CreditsModal } from './components/modals/CreditsModal'
import { StatsModal } from './components/modals/StatsModal'
import { ScoreModal } from './components/modals/ScoreModal'
import { SettingsModal } from './components/modals/SettingsModal'
import {
  GAME_COPIED_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  WORD_NOT_FOUND_MESSAGE,
  DISCOURAGE_INAPP_BROWSER_TEXT,
  ROUND_START_MESSAGE,
  TUTORIAL_GAME_START_MESSAGE,
  TUTORIAL_ROUND_START_MESSAGE,
  TUTORIAL_POINTS_EARNED_MESSAGE,
  TUTORIAL_TURN_SKIPPED_MESSAGE,
  TUTORIAL_OUT_OF_TIME_MESSAGE,
  TUTORIAL_LAST_CHANCE_FAILED_TEXT,
  TUTORIAL_LAST_CHANCE_OUT_OF_TIME_TEXT,
  TUTORIAL_OUT_OF_GUESSES_TEXT,
  TUTORIAL_STALEMATE_TEXT,
  TUTORIAL_GAME_OVER_MESSAGE,
} from './constants/strings'
import {
  MAX_GUESSES,
  REVEAL_TIME_MS,
  DISCOURAGE_INAPP_BROWSERS,
  PRE_ROUND_SECONDS,
  MAX_SECONDS_PER_TURN,
  TIMER_INEXACT_PRECISION,
  SKIPS_BEFORE_STALEMATE,
} from './constants/settings'
import {
  isWordInWordList,
  unicodeLength,
  getNextValidSolution,
  solutionLength,
} from './lib/words'
import {
  getGuessCountStats,
  setGuessCountStats,
  addStatsForGame,
  loadStats,
  resetStats,
} from './lib/stats'
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
  loadSettingsFromLocalStorage,
  saveSettingsToLocalStorage,
  getStoredStartPlayer,
  setStoredStartPlayer,
} from './lib/localStorage'
import { default as GraphemeSplitter } from 'grapheme-splitter'

import './App.css'
import { AlertContainer } from './components/alerts/AlertContainer'
import { useAlert } from './context/AlertContext'
import { Navbar } from './components/navbar/Navbar'
import { isInAppBrowser } from './lib/browser'

export type GamePhase =
  | 'pregame'
  | 'gameStart'
  | 'roundStart'
  | 'guessing'
  | 'lastChance'
  | 'roundSwitch'
  | 'ending'
  | 'ended'
export type GridState = 'open' | 'answered' | 'revealed'

export type RoundRecord = {
  solutions: string[]
  points: number[]
  guesses: number
}

export type GameState = {
  phase: GamePhase
  roundNumber: number
  guesses: string[]
  solutions: string[]
  gridStates: GridState[]
  finalGuessIndex: number[]
  scores: number[]
  duplicateCounts: number[]
  roundRecords: RoundRecord[]
  playerNumber: number
  passes: number
}

var prevMs = -1

function App() {
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches

  // Configure alert library
  const {
    showError: showErrorAlert,
    showSuccess: showSuccessAlert,
    isVisible: alertIsVisible,
  } = useAlert()

  /***************************************
   * Helper methods
   ****************************************/

  const pushNewSolutionsToState = (state: GameState) => {
    state.solutions = ['', '']

    function SetSolution(state: GameState, index: number, otherIndex: number) {
      let duplicateDelta =
        state.duplicateCounts[index] - state.duplicateCounts[otherIndex]
      let solutionPair = getNextValidSolution(
        state.solutions[otherIndex],
        duplicateDelta,
        state.roundRecords
      )
      state.solutions[index] = solutionPair.word
      state.duplicateCounts[index] += solutionPair.duplicates
    }

    SetSolution(state, 0, 1)
    SetSolution(state, 1, 0)

    state.roundRecords.push({
      solutions: state.solutions,
      points: [0, 0],
      guesses: 0,
    })
  }

  const getDefaultGameState = () => {
    const defaultState = {
      phase: 'pregame',
      roundNumber: 0,
      guesses: [],
      solutions: [],
      gridStates: ['open', 'open'],
      finalGuessIndex: [-1, -1],
      playerNumber: getStoredStartPlayer(),
      passes: 0,
      scores: [0, 0],
      duplicateCounts: [0, 0],
      roundRecords: [],
    } as GameState

    pushNewSolutionsToState(defaultState)

    return defaultState
  }

  const modifyGameState = (newState: any) => {
    setGameState((state) => {
      const mergedState = {
        ...state,
        ...newState,
      }
      return mergedState
    })
  }

  const getNextPlayer = () => {
    return gameState.playerNumber === 0 ? 1 : 0
  }

  const getWinnerPlayer = () => {
    const gameOver =
      gameState.scores.some((s) => s >= gameSettings.pointsToWin) &&
      gameState.scores[0] !== gameState.scores[1]
    if (!gameOver) {
      return null
    }

    return gameState.scores[0] > gameState.scores[1] ? 0 : 1
  }

  const resetTurnTimer = () => {
    setCurrentTime(gameSettings.secondsPerTurn)
  }

  const passTurn = () => {
    const passes = gameState.passes + 1
    modifyGameState({
      playerNumber: getNextPlayer(),
      passes: passes,
    })

    setCurrentGuess('')

    if (passes >= SKIPS_BEFORE_STALEMATE) {
      if (gameSettings.tutorialMessages) {
        showErrorAlert(TUTORIAL_STALEMATE_TEXT)
      }

      endRound()
    }
  }

  const endRound = () => {
    // Mark any unanswered grids as revealed
    let gridStates = gameState.gridStates
    for (let i = 0; i < gridStates.length; i++) {
      if (gridStates[i] === 'open') {
        gridStates[i] = 'revealed'
      }
    }

    let winnerPlayer = getWinnerPlayer()
    if (winnerPlayer !== null) {
      const winner = winnerPlayer
      modifyGameState({
        phase: 'ending',
        gridStates: gridStates,
      })

      if (gameSettings.tutorialMessages) {
        showSuccessAlert(TUTORIAL_GAME_OVER_MESSAGE(winnerPlayer))
      }

      setStats(addStatsForGame(stats, winner))
    } else {
      modifyGameState({
        phase: 'roundSwitch',
        gridStates: gridStates,
      })

      setCurrentTime(PRE_ROUND_SECONDS)
    }
  }

  const startNewRound = () => {
    const stateCopy = Object.assign({}, gameState)
    stateCopy.phase = 'roundStart'
    stateCopy.roundNumber++
    stateCopy.guesses = []
    stateCopy.gridStates = ['open', 'open']
    stateCopy.finalGuessIndex = [-1, -1]
    stateCopy.passes = 0

    pushNewSolutionsToState(stateCopy)

    setGameState(stateCopy)
    resetTurnTimer()
  }

  const clearCurrentRowClass = () => {
    setCurrentRowClass('')
  }

  /***************************************
   * State variables
   ****************************************/

  // Modal state
  const [isTwindleInfoModalOpen, setIsTwindleInfoModalOpen] = useState(false)
  const [isRulesInfoModalOpen, setIsRulesInfoModalOpen] = useState(false)
  const [isCreditsModalOpen, setIsCreditsModalOpen] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isScoreModalOpen, setIsScoreModalOpen] = useState(false)
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)

  // Game state
  const [currentGuess, setCurrentGuess] = useState('')
  const [gameState, setGameState] = useState(() => {
    const loaded = loadGameStateFromLocalStorage()

    const arrayExists = (x: any) => {
      return x !== null && x !== undefined && x.length === 2
    }

    let state = getDefaultGameState()

    // Load existing round state
    if (loaded !== null) {
      const loadedState = loaded as GameState
      if (
        arrayExists(loadedState.solutions) &&
        arrayExists(loadedState.scores) &&
        arrayExists(loadedState.gridStates) &&
        arrayExists(loadedState.finalGuessIndex) &&
        Object.values(loadedState).every(
          (x) => x !== null && typeof x !== 'undefined'
        )
      ) {
        state = loadedState
      }
    }

    if (state.phase === 'pregame') {
      setIsTwindleInfoModalOpen(true)
    }

    return state
  })

  // Start with an intentional amount of time so if the user refreshes in a state with a timer
  // (such as roundSwitch), we will have a valid time to tick down from
  const [currentTime, setCurrentTime] = useState(PRE_ROUND_SECONDS)

  // Display state
  const [currentRowClass, setCurrentRowClass] = useState('')
  const [isRevealing, setIsRevealing] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') === 'dark'
      : prefersDarkMode
      ? true
      : false
  )

  const [gameSettings, setGameSettings] = useState(
    loadSettingsFromLocalStorage()
  )

  // Cross-game stats
  const [stats, setStats] = useState(() => loadStats())

  /***************************************
   * Runtime variables
   ****************************************/

  const anyModalOpen =
    isTwindleInfoModalOpen ||
    isRulesInfoModalOpen ||
    isCreditsModalOpen ||
    isSettingsModalOpen ||
    isStatsModalOpen ||
    isScoreModalOpen
  const inputAllowed =
    !(isRevealing || anyModalOpen) &&
    (gameState.phase === 'guessing' || gameState.phase === 'lastChance')
  const scoreChanged = [false, false]

  const isGameRunning = gameState.phase !== 'pregame'

  const isGameplayPhase =
    gameState.phase === 'guessing' || gameState.phase === 'lastChance'

  const hasUnlimitedTime =
    isGameplayPhase && gameSettings.secondsPerTurn === MAX_SECONDS_PER_TURN // We treat maxing out the turn time slider as unlimited time

  /***************************************
   * React effect callbacks
   ****************************************/

  // Set up game loop
  useEffect(() => {
    const updateTime = () => {
      if (prevMs < 0) {
        prevMs = Date.now()
        return
      }

      let nowMs = Date.now()
      tick((nowMs - prevMs) / 1000)
      prevMs = nowMs
    }

    // Refresh at 30fps on mobile to save battery life
    const timer = setInterval(updateTime, isMobile ? 33 : 16)

    return () => {
      clearInterval(timer)
    }
  })

  // Show warning when using in-app browsers
  useEffect(() => {
    DISCOURAGE_INAPP_BROWSERS &&
      isInAppBrowser() &&
      showErrorAlert(DISCOURAGE_INAPP_BROWSER_TEXT, {
        persist: false,
        durationMs: 7000,
      })
  }, [showErrorAlert])

  // Keep style sheets up to date
  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    if (gameSettings.highContrast) {
      document.documentElement.classList.add('high-contrast')
    } else {
      document.documentElement.classList.remove('high-contrast')
    }
  }, [isDarkMode, gameSettings.highContrast])

  // Save game state to storage whenever it changes
  useEffect(() => {
    saveGameStateToLocalStorage(gameState)
  }, [gameState])

  // Save settings to storage whenever it changes
  useEffect(() => {
    saveSettingsToLocalStorage(gameSettings)
  }, [gameSettings])

  // Restart time remaining whenever the player changes
  useEffect(() => {
    if (gameState.phase === 'guessing' || gameState.phase === 'lastChance') {
      resetTurnTimer()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState.playerNumber])

  /***************************************
   * UI callbacks
   ****************************************/

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark)
    localStorage.setItem('theme', isDark ? 'dark' : 'light')
  }

  const handleTutorialMessages = (enabled: boolean) => {
    const settings = { ...gameSettings }
    settings.tutorialMessages = enabled
    setGameSettings(settings)
  }

  const handleHighContrastMode = (isHighContrast: boolean) => {
    const settings = { ...gameSettings }
    settings.highContrast = isHighContrast
    setGameSettings(settings)
  }

  const handleShowTimerNumbers = (show: boolean) => {
    const settings = { ...gameSettings }
    settings.showTimerNumbers = show
    setGameSettings(settings)
  }

  const handleSetPointsToWin = (points: number) => {
    const settings = { ...gameSettings }
    settings.pointsToWin = points
    console.log(settings)

    setGameSettings(settings)
  }

  const handleSetSecondsPerTurn = (seconds: number) => {
    const settings = { ...gameSettings }
    settings.secondsPerTurn = seconds
    setGameSettings(settings)
  }

  const handleRestartGame = () => {
    // Set to true to show the initial info popup whenever you reset the game
    const isDevMode = false

    setIsScoreModalOpen(false)
    setIsSettingsModalOpen(false)
    setIsTwindleInfoModalOpen(isDevMode)

    const startPlayer = getStoredStartPlayer()
    setStoredStartPlayer(startPlayer === 0 ? 1 : 0)

    setGameState(getDefaultGameState())

    resetTurnTimer()
  }

  /***************************************
   * Game logic
   ****************************************/

  const updateImmediateStates = () => {
    switch (gameState.phase) {
      case 'pregame':
        if (gameSettings.tutorialMessages) {
          showSuccessAlert(
            TUTORIAL_GAME_START_MESSAGE(gameSettings.pointsToWin)
          )
        }

        setCurrentTime(PRE_ROUND_SECONDS)

        modifyGameState({
          phase: 'gameStart',
        })

        return true
      case 'roundStart':
        if (gameSettings.tutorialMessages) {
          showSuccessAlert(
            TUTORIAL_ROUND_START_MESSAGE(
              gameState.roundNumber,
              gameState.playerNumber
            )
          )
        } else {
          showSuccessAlert(ROUND_START_MESSAGE(gameState.roundNumber))
        }
        modifyGameState({ phase: 'guessing' })

        return true
      case 'ending':
        setIsScoreModalOpen(true)
        modifyGameState({ phase: 'ended' })

        return true
      case 'ended':
        // Keep the score modal visible during the end phase
        if (!anyModalOpen) {
          setIsScoreModalOpen(true)
        }

        return true
    }

    return false
  }

  const tick = (deltaTime: number) => {
    const ticksPaused = isRevealing || alertIsVisible || anyModalOpen
    if (ticksPaused) {
      return
    }

    let tickHandled = updateImmediateStates()
    if (tickHandled) {
      return
    }

    // Make sure we don't have more time than the current settings allow
    if (isGameplayPhase && currentTime > gameSettings.secondsPerTurn) {
      setCurrentTime(gameSettings.secondsPerTurn)
      return
    }

    // Never run out of time if we have enabled infinite time
    if (hasUnlimitedTime) {
      return
    }

    // Tick timer
    if (currentTime > 0) {
      var newTime = Math.max(0, currentTime - deltaTime)
      setCurrentTime(newTime)

      // Timer expired
      if (newTime === 0) {
        switch (gameState.phase) {
          case 'gameStart':
            modifyGameState({
              phase: 'roundStart',
            })

            resetTurnTimer()
            break
          case 'guessing':
            if (gameSettings.tutorialMessages) {
              showErrorAlert(TUTORIAL_OUT_OF_TIME_MESSAGE(getNextPlayer()))
            }

            passTurn()
            break
          case 'lastChance':
            if (gameSettings.tutorialMessages) {
              showErrorAlert(TUTORIAL_LAST_CHANCE_OUT_OF_TIME_TEXT)
            }
            endRound()
            break
          case 'roundSwitch':
            startNewRound()
            break
        }
      }
    }
  }

  /***************************************
   * Input methods
   ****************************************/

  const onChar = (value: string) => {
    if (!inputAllowed) return

    if (unicodeLength(`${currentGuess}${value}`) <= solutionLength) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    if (!inputAllowed) return

    setCurrentGuess(
      new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -1).join('')
    )
  }

  const onEnter = () => {
    if (!inputAllowed) return

    if (!(unicodeLength(currentGuess) === solutionLength)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(NOT_ENOUGH_LETTERS_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    if (!isWordInWordList(currentGuess)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(WORD_NOT_FOUND_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    const stateCopy = Object.assign({}, gameState)

    // We have a valid input, so clear out all the passes
    stateCopy.passes = 0

    const checkSolution = (i: number) => {
      if (
        gameState.solutions[i] === currentGuess &&
        stateCopy.gridStates[i] === 'open'
      ) {
        const isLastChance = stateCopy.phase === 'lastChance'
        const points = isLastChance ? 1 : 2 // Assign one point for last chance guesses
        const otherPlayer = i === 0 ? 1 : 0

        scoreChanged[i] = true
        stateCopy.scores[i] += points
        stateCopy.gridStates[i] = 'answered'
        stateCopy.finalGuessIndex[i] = stateCopy.guesses.length - 1
        stateCopy.playerNumber = otherPlayer // Allow the other player to go next, regardless of who guesses before

        const roundRecord = stateCopy.roundRecords.pop()
        if (roundRecord) {
          roundRecord.points[i] = points // Track the points for this individual round
          if (!isLastChance) {
            roundRecord.guesses = stateCopy.guesses.length
          }
          stateCopy.roundRecords.push(roundRecord)
        }

        if (gameSettings.tutorialMessages) {
          showSuccessAlert(
            TUTORIAL_POINTS_EARNED_MESSAGE(i, otherPlayer, points, isLastChance)
          )
        }

        return true
      }

      return false
    }

    setIsRevealing(true)
    // turn this back off after all
    // chars have been revealed
    setTimeout(() => {
      setIsRevealing(false)
    }, REVEAL_TIME_MS * solutionLength)

    if (unicodeLength(currentGuess) === solutionLength) {
      stateCopy.guesses = [...gameState.guesses, currentGuess]
      stateCopy.playerNumber = getNextPlayer()

      const eitherCorrect = checkSolution(0) || checkSolution(1)

      if (eitherCorrect) {
        const newCount = getGuessCountStats(stats, stateCopy.guesses.length) + 1
        setStats((s) =>
          setGuessCountStats(s, stateCopy.guesses.length, newCount)
        )
      }

      // When one player wins, give the other player a chance to guess one final time
      if (eitherCorrect && stateCopy.phase === 'guessing') {
        stateCopy.phase = 'lastChance'
      }

      const outOfGuesses =
        stateCopy.phase === 'guessing' &&
        stateCopy.guesses.length === MAX_GUESSES
      if (outOfGuesses) {
        showErrorAlert(TUTORIAL_OUT_OF_GUESSES_TEXT)
      } else if (gameState.phase === 'lastChance' && !eitherCorrect) {
        showErrorAlert(TUTORIAL_LAST_CHANCE_FAILED_TEXT)
      }

      setGameState(stateCopy)
      setCurrentGuess('')

      // If we're out of guesses or the previous guess was a last chance, we need to move to the next round
      if (outOfGuesses || gameState.phase === 'lastChance') {
        endRound()
      } else {
        // Manually reset the turn timer in case we didn't change the active player
        resetTurnTimer()
      }
    }
  }

  /***************************************
   * UI rendering
   ****************************************/

  const isMobile = ('ontouchstart' in document.documentElement &&
    navigator.userAgent.match(/Mobi/)) as boolean

  const isPreGame = gameState.phase === 'pregame'

  const betweenRounds =
    gameState.phase === 'roundSwitch' || gameState.phase === 'gameStart'

  const timerVisible = (isGameplayPhase && !hasUnlimitedTime) || betweenRounds

  const activeSolution = isGameplayPhase
    ? gameState.solutions[gameState.playerNumber]
    : ''

  const totalTimerSeconds = betweenRounds
    ? PRE_ROUND_SECONDS
    : gameSettings.secondsPerTurn

  let customTimerText = null
  if (betweenRounds) {
    customTimerText = 'WAIT'
  } else if (currentTime > totalTimerSeconds - TIMER_INEXACT_PRECISION) {
    customTimerText = 'GO'
  }

  const timer = (
    <Timer
      seconds={currentTime}
      totalSeconds={totalTimerSeconds}
      customText={customTimerText}
      numbersEnabled={gameSettings.showTimerNumbers}
      hidden={!timerVisible}
    />
  )

  let centerElements = []
  if (isMobile) {
    centerElements.push(
      <div
        key="mainDiv"
        className="pb-6 flex flex-col items-center relative -top-4 sm:-top-8"
      >
        {timer}
        <Arrow
          direction={gameState.playerNumber === 0 ? 'left' : 'right'}
          hidden={!isGameplayPhase}
        />
      </div>
    )
  } else {
    centerElements.push(
      <Arrow
        key="left"
        direction={'left'}
        hidden={gameState.playerNumber !== 0 || !isGameplayPhase}
      />
    )
    centerElements.push(
      <div
        key="mainDiv"
        className="pb-6 flex flex-col items-center relative -top-4 sm:-top-8"
      >
        {timer}
        <LetterGrid
          solution={activeSolution}
          guesses={gameState.guesses}
          isHighContrast={gameSettings.highContrast}
          isRevealing={isRevealing || gameState.phase === 'roundSwitch'}
        />
      </div>
    )
    centerElements.push(
      <Arrow
        key="right"
        direction={'right'}
        hidden={gameState.playerNumber !== 1 || !isGameplayPhase}
      />
    )
  }

  return (
    <div className="flex flex-col my-auto h-full">
      <Navbar
        setIsCreditsModalOpen={setIsCreditsModalOpen}
        setIsRulesInfoModalOpen={setIsTwindleInfoModalOpen}
        setIsStatsModalOpen={setIsStatsModalOpen}
        setIsSettingsModalOpen={setIsSettingsModalOpen}
      />
      <div className="pt-1 pb-8 md:max-w-7xl w-full mx-auto sm:px-6 lg:px-8 flex flex-col grow">
        <TurnBar
          turnsSkipped={gameState.passes}
          onClickSkipTurn={() => {
            if (!inputAllowed) return

            if (gameState.phase !== 'guessing') return

            if (gameSettings.tutorialMessages) {
              showSuccessAlert(TUTORIAL_TURN_SKIPPED_MESSAGE(getNextPlayer()))
            }

            passTurn()
          }}
        />
        <div className="flex flex-row justify-center sm:justify-around">
          <Grid
            solution={gameState.solutions[0]}
            guesses={gameState.guesses}
            finalGuessIndex={gameState.finalGuessIndex[0]}
            currentGuess={currentGuess}
            isRevealing={isRevealing}
            state={gameState.gridStates[0]}
            score={gameState.scores[0] + ''}
            scoreChanged={scoreChanged[0]}
            currentRowClassName={currentRowClass}
            isHighContrast={gameSettings.highContrast}
          />
          {centerElements}
          <Grid
            solution={gameState.solutions[1]}
            guesses={gameState.guesses}
            finalGuessIndex={gameState.finalGuessIndex[1]}
            currentGuess={currentGuess}
            state={gameState.gridStates[1]}
            score={gameState.scores[1] + ''}
            scoreChanged={scoreChanged[1]}
            isRevealing={isRevealing}
            currentRowClassName={currentRowClass}
            isHighContrast={gameSettings.highContrast}
          />
        </div>
        <div className="grow" />
        <Keyboard
          onChar={onChar}
          onDelete={onDelete}
          onEnter={onEnter}
          solution={activeSolution}
          guesses={gameState.guesses}
          isMobile={isMobile}
          isRevealing={isRevealing || gameState.phase === 'roundSwitch'}
          isHighContrast={gameSettings.highContrast}
        />
        <CreditsModal
          isOpen={isCreditsModalOpen}
          handleClose={() => setIsCreditsModalOpen(false)}
        />
        <TwindleInfoModal
          isOpen={isTwindleInfoModalOpen}
          isHighContrast={gameSettings.highContrast}
          handleClose={() => {
            if (!isPreGame) {
              setIsTwindleInfoModalOpen(false)
            }
          }}
          isPreGame={isPreGame}
          handleNewGame={() => setIsTwindleInfoModalOpen(false)}
          handleRulesInfo={() => {
            setIsRulesInfoModalOpen(true)
            setIsTwindleInfoModalOpen(false)
          }}
        />
        <RulesInfoModal
          isOpen={isRulesInfoModalOpen}
          isHighContrast={gameSettings.highContrast}
          handleClose={() => {
            if (!isPreGame) {
              setIsRulesInfoModalOpen(false)
            }
          }}
          isPreGame={isPreGame}
          handleTwindleInfo={() => {
            setIsTwindleInfoModalOpen(true)
            setIsRulesInfoModalOpen(false)
          }}
          handleNewGame={() => setIsRulesInfoModalOpen(false)}
        />
        <StatsModal
          isOpen={isStatsModalOpen}
          handleClose={() => setIsStatsModalOpen(false)}
          gameStats={stats}
          handleResetStats={() => setStats(resetStats())}
        />
        <SettingsModal
          isOpen={isSettingsModalOpen}
          handleClose={() => setIsSettingsModalOpen(false)}
          isShowTutorialMessaegs={gameSettings.tutorialMessages}
          handleShowTutorialMessages={handleTutorialMessages}
          isDarkMode={isDarkMode}
          handleDarkMode={handleDarkMode}
          isHighContrastMode={gameSettings.highContrast}
          handleHighContrastMode={handleHighContrastMode}
          showTimerNumbers={gameSettings.showTimerNumbers}
          handleShowTimerNumbers={handleShowTimerNumbers}
          pointsToWin={gameSettings.pointsToWin}
          handleSetPointsToWin={handleSetPointsToWin}
          secondsPerTurn={gameSettings.secondsPerTurn}
          handleSetSecondsPerTurn={handleSetSecondsPerTurn}
          isGameRunning={isGameRunning}
          handleRestartGame={handleRestartGame}
        />
        <ScoreModal
          isOpen={isScoreModalOpen}
          handleClose={() => {}} // Don't allow people to manually close the score modal
          gameState={gameState}
          handleNewGame={() => {
            handleRestartGame()
          }}
          handleShareToClipboard={() => showSuccessAlert(GAME_COPIED_MESSAGE)}
          isHighContrast={gameSettings.highContrast}
        />
        <AlertContainer />
      </div>
    </div>
  )
}

export default App
