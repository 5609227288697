import { StatBar } from '../stats/StatBar'
import { Histogram } from '../stats/Histogram'
import { GameStats } from '../../lib/localStorage'
import { BaseModal } from './BaseModal'
import { SettingsButton } from './SettingsButton'
import {
  STATISTICS_TITLE,
  GUESS_DISTRIBUTION_TEXT,
  RESET_STATS_TEXT,
} from '../../constants/strings'

type Props = {
  isOpen: boolean
  handleClose: () => void
  gameStats: GameStats,
  handleResetStats: () => void
}

export const StatsModal = ({
  isOpen,
  handleClose,
  gameStats,
  handleResetStats,
}: Props) => {  
  return (
    <BaseModal
      title={STATISTICS_TITLE}
      isOpen={isOpen}
      showCloseButton={true} 
      handleClose={handleClose}
    >
      <StatBar gameStats={gameStats} />
      <h4 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
        {GUESS_DISTRIBUTION_TEXT}
      </h4>
      <Histogram
        gameStats={gameStats}
      />
      
      <SettingsButton
        handleClick={handleResetStats}
        enabled={true}
        description={RESET_STATS_TEXT}
      />  
    </BaseModal>
  )
}
