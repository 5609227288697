import { BaseModal } from './BaseModal'
import { SettingsToggle } from './SettingsToggle'
import { SettingsButton } from './SettingsButton'
import { SettingsRange } from './SettingsRange'
import {
  TUTORIAL_MESSAGES_DESCRIPTION,
  HIGH_CONTRAST_MODE_DESCRIPTION,
  SHOW_TIMER_NUMBERS_DESCRIPTION,
  POINTS_TO_WIN_DESCRIPTION,
  SECONDS_PER_TURN_DESCRIPTION
} from '../../constants/strings'
import {
  MIN_POINTS_TO_WIN,
  MAX_POINTS_TO_WIN,
  POINTS_TO_WIN_STEP,
  MIN_SECONDS_PER_TURN,
  MAX_SECONDS_PER_TURN,
  SECONDS_PER_TURN_STEP
} from '../../constants/settings'

type Props = {
  isOpen: boolean
  handleClose: () => void
  isShowTutorialMessaegs: boolean
  handleShowTutorialMessages: Function
  isDarkMode: boolean
  handleDarkMode: Function
  isHighContrastMode: boolean
  handleHighContrastMode: Function
  showTimerNumbers: boolean
  handleShowTimerNumbers: Function
  pointsToWin: number,
  handleSetPointsToWin: Function,
  secondsPerTurn: number,
  handleSetSecondsPerTurn: Function,
  isGameRunning: boolean
  handleRestartGame: Function
}

export const SettingsModal = ({
  isOpen,
  handleClose,
  isShowTutorialMessaegs,
  handleShowTutorialMessages,
  isDarkMode,
  handleDarkMode,
  isHighContrastMode,
  handleHighContrastMode,
  showTimerNumbers,
  handleShowTimerNumbers,
  pointsToWin,
  handleSetPointsToWin,
  secondsPerTurn,
  handleSetSecondsPerTurn,
  isGameRunning,
  handleRestartGame
}: Props) => {
  return (
    <BaseModal title="Settings" isOpen={isOpen}  showCloseButton={true} handleClose={handleClose}>
      <div className="flex flex-col mt-2 divide-y">
        <SettingsToggle
          settingName="Tutorial Messages"
          flag={isShowTutorialMessaegs}
          handleFlag={handleShowTutorialMessages}
          description={TUTORIAL_MESSAGES_DESCRIPTION}
        />
        <SettingsToggle
          settingName="Dark Mode"
          flag={isDarkMode}
          handleFlag={handleDarkMode}
        />
        <SettingsToggle
          settingName="High Contrast Mode"
          flag={isHighContrastMode}
          handleFlag={handleHighContrastMode}
          description={HIGH_CONTRAST_MODE_DESCRIPTION}
        />
        <SettingsToggle
          settingName="Timer Numbers"
          flag={showTimerNumbers}
          handleFlag={handleShowTimerNumbers}
          description={SHOW_TIMER_NUMBERS_DESCRIPTION}
        />
        <SettingsRange
          settingName='Points to Win'
          value={pointsToWin}
          min={MIN_POINTS_TO_WIN}
          max={MAX_POINTS_TO_WIN}
          step={POINTS_TO_WIN_STEP}
          handleChange={handleSetPointsToWin}
          description={POINTS_TO_WIN_DESCRIPTION}
        />
        <SettingsRange
          settingName='Seconds per Turn'
          value={secondsPerTurn}
          min={MIN_SECONDS_PER_TURN}
          max={MAX_SECONDS_PER_TURN}
          step={SECONDS_PER_TURN_STEP}
          handleChange={handleSetSecondsPerTurn}
          description={SECONDS_PER_TURN_DESCRIPTION}
          customValueLabel={secondsPerTurn === MAX_SECONDS_PER_TURN ? "∞" : undefined}
        />
        <SettingsButton
          handleClick={handleRestartGame}
          enabled={isGameRunning}
          description='Restart Game'
          disabledMessage='You can only restart when the game is already running.'
        />
      </div>
    </BaseModal>
  )
}
