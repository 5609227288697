import { ReactNode } from "react";
export const Tooltip = ({ 
  message, children 
} : {
  message: string, children: ReactNode 
}) => {
  return (
	<div className="relative flex flex-col items-center group">
	  {children}
	  <div className="absolute top-8 flex flex-col items-center opacity-0 mb-6 group-hover:opacity-100 transition-opacity select-none delay-700 duration-300">
		<div className="w-3 h-3 -mb-2 rotate-45 bg-gray-400 dark:bg-gray-600"></div>
		<span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-400 dark:bg-gray-600 shadow-lg rounded-md">{message}</span>
	  </div>
	</div>
  );
};