import classnames from 'classnames'
import { MAX_EXACT_TIME_SECONDS, TIMER_INEXACT_PRECISION } from '../../constants/settings'
import { TimerRing } from './TimerRing'

type Props = {
  seconds: number
  totalSeconds: number
  customText: string | null,
  numbersEnabled: boolean,
  hidden?: boolean
}

export const Timer = ({
  seconds,
  totalSeconds,
  customText,
  numbersEnabled,
  hidden
}: Props) => {

  const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);
  const percentage = clamp(seconds/totalSeconds, 0, 1);
  let displayTime = Math.ceil(seconds);
  if (displayTime > MAX_EXACT_TIME_SECONDS) {
    // Show a reduced accuracy time remaining to avoid constant visual flicker
    displayTime = Math.ceil(displayTime / TIMER_INEXACT_PRECISION) * TIMER_INEXACT_PRECISION;
  }
  
  const divClasses = classnames(
    "w-12 h-12 sm:w-14 sm:h-14 md:w-28 md:h-28 mb-4 relative",
    {
      'invisible' : hidden,
    }
  )

  const textContent = customText !== null ? customText : '' + displayTime;
  const smallText = textContent.length > 3; 
  console.log(smallText)

  const textClasses = classnames(
    'text-center font-bold dark:text-slate-200 text-slate-300 select-none absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2',
    {
      'invisible' : customText === null && !numbersEnabled,
      'text-xs md:text-3xl' : smallText,
      'text-2x1 md:text-5xl' : !smallText
      //'animate-pulse' : !customText && displayTime <= MAX_EXACT_TIME_SECONDS
    }
  )
  
  return (
    <div className={divClasses}>
      <TimerRing fillPercentage={percentage} />
      <div className={textClasses}>{textContent}</div>
    </div>
  )
}
