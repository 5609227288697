import {
  ChartBarIcon,
  CogIcon,
  InformationCircleIcon,
  QuestionMarkCircleIcon
} from '@heroicons/react/outline'
import { GAME_TITLE } from '../../constants/strings'
import { Tooltip } from '../tooltip/Tooltip'

type Props = {
  setIsCreditsModalOpen: (value: boolean) => void
  setIsRulesInfoModalOpen: (value: boolean) => void
  setIsStatsModalOpen: (value: boolean) => void
  setIsSettingsModalOpen: (value: boolean) => void
}

export const Navbar = ({
  setIsCreditsModalOpen,
  setIsRulesInfoModalOpen,
  setIsStatsModalOpen,
  setIsSettingsModalOpen,
}: Props) => {
  return (
    <div className="navbar mb-2 sm:mb-4">
      <div className="navbar-content px-5">
        <div className="flex">
        <Tooltip message='Credits'>
          <InformationCircleIcon
            className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white"
            onClick={() => setIsCreditsModalOpen(true)}
          />
        </Tooltip>
        <Tooltip message='Tutorial'>
          <QuestionMarkCircleIcon
            className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white"
            onClick={() => setIsRulesInfoModalOpen(true)}
          />
        </Tooltip>
        </div>
        <p className="text-xl ml-2.5 font-bold dark:text-white">{GAME_TITLE}</p>
        <div className="flex">
          <Tooltip message='Stats'>
            <ChartBarIcon
              className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
              onClick={() => setIsStatsModalOpen(true)}
            />
          </Tooltip>
          <Tooltip message="Settings">
            <CogIcon
              className="h-6 w-6 cursor-pointer dark:stroke-white"
              onClick={() => setIsSettingsModalOpen(true)}
            />
          </Tooltip>
        </div>
      </div>
      <hr></hr>
    </div>
  )
}
