import { Cell } from './Cell'
import { unicodeSplit } from '../../lib/words'

type Props = {
  solution: string
}

export const RevealedRow = ({ solution }: Props) => {
  const splitSolution = unicodeSplit(solution)

  return (
    <div className="flex justify-center mb-1">
      {splitSolution.map((letter, i) => (
        <Cell
          key={i}
          value={letter}
          position={i}
          status={'revealed'}
          isRevealing={false}
          isCompleted
        />
      ))}
    </div>
  )
}
