import { BaseModal } from './BaseModal'
import { SettingsButton } from './SettingsButton'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const CreditsModal = ({ isOpen, handleClose}: Props) => {
  const linkClasses = "underline";
  const headerClasses = "font-bold"
  
  return (
    <BaseModal title="Credits" isOpen={isOpen} showCloseButton={true} handleClose={handleClose}>
      <div className="space-y-4 text-center text-base text-gray-500 dark:text-gray-300 mb-4">
        <div>
          <h1 className={headerClasses}>Development</h1>
          <a tabIndex={-1} target="_blank" rel="noreferrer" href="http://jonagill.com" className={linkClasses}>Jon Gill</a>
        </div>
        
        <div>
          <h1 className={headerClasses}>Inspired by</h1>
          <a tabIndex={-1} target="_blank" rel="noreferrer" href="https://www.powerlanguage.co.uk/" className={linkClasses}>Josh Wardle</a>
        </div>
        
        <div>
          <h1 className={headerClasses}>Based on code by</h1>
          <p>Hannah Park and Chase Wackerfuss</p>
        </div>
        
        <div>
          <h1 className={headerClasses}>Special Thanks</h1>
          <p className="text-sm">Matthew Moore</p>
          <p className="text-sm">Evan Lewis</p>
          <p className="text-sm">Rob Giusti</p>
          <p className="text-sm">Andy Clautice</p>
        </div>
          
      </div>
      
      <SettingsButton
        handleClick={() => window.open("https://ko-fi.com/jonagill")}
        enabled={true}
        description='Like Twindle? Tip on Ko-Fi'
      />
      
    </BaseModal>
  )
}
