import { WORDS } from '../constants/wordlist'
import { VALID_GUESSES } from '../constants/validGuesses'
import { WRONG_SPOT_MESSAGE, NOT_CONTAINED_MESSAGE } from '../constants/strings'
import { 
  MAX_DUPLICATE_LETTERS_WITHIN_WORD,
  MAX_DUPLICATE_LETTER_DELTA_BETWEEN_PLAYERS,
  MAX_SHARED_LETTERS_BETWEEN_WORDS,
  MAX_SHARED_LETTER_POSITIONS_BETWEEN_WORDS,
  MIN_ROUNDS_BETWEEN_WORDS_ENDING_IN_S
} from '../constants/settings'
import { getGuessStatuses } from './statuses'
import { default as GraphemeSplitter } from 'grapheme-splitter'
import { getStoredNextSolutionIndex, setStoredNextSolutionIndex, getStoredShuffledSolutions, setStoredShuffledSolutions } from './localStorage'
import { RoundRecord } from '../App'

export const solutionLength = WORDS[0].length;

export const isWordInWordList = (word: string) => {
  return (
    shuffledSolutions.includes(localeAwareLowerCase(word)) ||
    VALID_GUESSES.includes(localeAwareLowerCase(word))
  )
}

// build a set of previously revealed letters - present and correct
// guess must use correct letters in that space and any other revealed letters
// also check if all revealed instances of a letter are used (i.e. two C's)
export const findFirstUnusedReveal = (word: string, guesses: string[], solution: string) => {
  if (guesses.length === 0) {
    return false
  }

  const lettersLeftArray = new Array<string>()
  const guess = guesses[guesses.length - 1]
  const statuses = getGuessStatuses(solution, guess)
  const splitWord = unicodeSplit(word)
  const splitGuess = unicodeSplit(guess)

  for (let i = 0; i < splitGuess.length; i++) {
    if (statuses[i] === 'correct' || statuses[i] === 'present') {
      lettersLeftArray.push(splitGuess[i])
    }
    if (statuses[i] === 'correct' && splitWord[i] !== splitGuess[i]) {
      return WRONG_SPOT_MESSAGE(splitGuess[i], i + 1)
    }
  }

  // check for the first unused letter, taking duplicate letters
  // into account - see issue #198
  let n
  for (const letter of splitWord) {
    n = lettersLeftArray.indexOf(letter)
    if (n !== -1) {
      lettersLeftArray.splice(n, 1)
    }
  }

  if (lettersLeftArray.length > 0) {
    return NOT_CONTAINED_MESSAGE(lettersLeftArray[0])
  }
  return false
}

export const unicodeSplit = (word: string) => {
  return new GraphemeSplitter().splitGraphemes(word)
}

export const unicodeLength = (word: string) => {
  return unicodeSplit(word).length
}

export const localeAwareLowerCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text.toLocaleLowerCase(process.env.REACT_APP_LOCALE_STRING)
    : text.toLowerCase()
}

export const localeAwareUpperCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text.toLocaleUpperCase(process.env.REACT_APP_LOCALE_STRING)
    : text.toUpperCase()
}

var shuffledSolutions : string[] = getStoredShuffledSolutions();
var nextSolutionIndex = getStoredNextSolutionIndex();

export const getNextValidSolution = (otherWord : string, duplicateDelta: number, records : RoundRecord[]) => 
{
  function WordHasTrailingS(word: string) {
    return word.length > 0 && word[word.length-1] === 'S'; 
  }
  
  function WordIsValid(word: string, otherWord: string, canEndInS: boolean)
  {    
    function ConstructResult(valid: boolean, duplicates?: number) {
      return {
        valid: valid,
        duplicates: duplicates
      }
    }
  
    function CountLetters(word: string, filter: Function) {
      let count = 0;
      for (let i = 0; i < word.length; i++) {
        if (filter(word[i])) {
          count++;
        }
      }
      
      return count;
    }
  
    if (word.length === 0)
    {
        return ConstructResult(false)
    }
    
    if (!canEndInS && WordHasTrailingS(word)) {
      return ConstructResult(false)
    }
    
    if (otherWord.length > 0)
    {
        // Don't allow too many shared letters between both words
        let sharedLetters = CountLetters(word, (l1 : string) => { return CountLetters(otherWord, (l2 : string) => l2 === l1) >= 1 });
        if (sharedLetters > MAX_SHARED_LETTERS_BETWEEN_WORDS) {
          return ConstructResult(false)
        }
      
        // Don't allow too many exact matching letters between both words
        let sharedPositions = 0;
        for (let i = 0; i < word.length; i++)
        {
            if (word[i] === otherWord[i])
            {
                sharedPositions++;
                if (sharedPositions > MAX_SHARED_LETTER_POSITIONS_BETWEEN_WORDS)
                {
                  return ConstructResult(false)
                }
            }
        }
    }
    
    // Don't allow too many duplicates within the same word
    // Count up the number of letters that appear multiple times in the same word
    let duplicates = CountLetters(word, (l1 : string) => { return CountLetters(word, (l2 : string) => l2 === l1) >= 2 });        
    if (duplicates > MAX_DUPLICATE_LETTERS_WITHIN_WORD)
    {
      return ConstructResult(false)
    }
    
    // Don't allow each player to experience too many more duplicates than the others
    if (duplicateDelta + duplicates > MAX_DUPLICATE_LETTER_DELTA_BETWEEN_PLAYERS)
    {
      return ConstructResult(false)
    }

    return ConstructResult(true, duplicates);
  }
  
  let roundsSinceTrailingS = Number.MAX_SAFE_INTEGER;
  let sFound = false;
  for (var i = 1; i < records.length; i++) {
    if (sFound) break;
    
    const recordIndex = records.length - i;
    for (var solutionIndex = 0; solutionIndex < records[recordIndex].solutions.length; solutionIndex++)
    {
      if (WordHasTrailingS(records[recordIndex].solutions[solutionIndex])) {
        roundsSinceTrailingS = i;
        sFound = true;
        break;
      }
    }  
  }
  
  const wordCanHaveTrailingS = roundsSinceTrailingS > MIN_ROUNDS_BETWEEN_WORDS_ENDING_IN_S;
    
  let word = "";
  let duplicates = 0;
  let tries = 0;
  while (tries < 50)
  {
      word = getNextRandomSolution();
      let validateResult = WordIsValid(word, otherWord, wordCanHaveTrailingS);
      if (validateResult.valid) 
      {
        duplicates = validateResult.duplicates !== undefined ? validateResult.duplicates : 0;
        break;
      }
      
      tries++;
  }
  
  return {
    word: word,
    duplicates: duplicates
  };
}

const getNextRandomSolution = (forceShuffle? : boolean) => 
{
  if (nextSolutionIndex >= shuffledSolutions.length || forceShuffle) 
  {
    shuffledSolutions = WORDS.slice();
    shuffle(shuffledSolutions);
    
    setStoredShuffledSolutions(shuffledSolutions);
    
    nextSolutionIndex = 0;
  }
  
  const solution = shuffledSolutions[nextSolutionIndex];
  
  nextSolutionIndex++;
  setStoredNextSolutionIndex(nextSolutionIndex);
  
  return localeAwareUpperCase(solution);
}

function shuffle<T>(array : Array<T>) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}