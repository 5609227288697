export const MAX_GUESSES = 8
export const PRE_ROUND_SECONDS = 5
export const MAX_EXACT_TIME_SECONDS = 10
export const TIMER_INEXACT_PRECISION = 5
export const SKIPS_BEFORE_STALEMATE = 6

export const DEFAULT_POINTS_TO_WIN = 7
export const MIN_POINTS_TO_WIN = 3
export const MAX_POINTS_TO_WIN = 9
export const POINTS_TO_WIN_STEP = 2

export const MAX_SHARED_LETTERS_BETWEEN_WORDS = 2
export const MAX_SHARED_LETTER_POSITIONS_BETWEEN_WORDS = 1
export const MAX_DUPLICATE_LETTERS_WITHIN_WORD = 2
export const MAX_DUPLICATE_LETTER_DELTA_BETWEEN_PLAYERS = 2
export const MIN_ROUNDS_BETWEEN_WORDS_ENDING_IN_S = 4

export const DEFAULT_SECONDS_PER_TURN = 60
export const MIN_SECONDS_PER_TURN = 15
export const MAX_SECONDS_PER_TURN = 135 // The highest step will be treated as infinite
export const SECONDS_PER_TURN_STEP = 15

export const ALERT_TIME_MS = 2000
export const REVEAL_TIME_MS = 350
export const LETTER_REVEAL_DELAY_MS = 1000
export const WELCOME_INFO_MODAL_MS = 350
export const DISCOURAGE_INAPP_BROWSERS = true
